<script lang="ts" setup>
export interface RatioBindValues {
  xAspect: number
  yAspect: number
}

type Props = {
  xAspect?: number
  yAspect?: number
}

const props = withDefaults(defineProps<Props>(), {
  xAspect: 1,
  yAspect: 1,
})

const ratioPadding = computed<Record<'paddingTop', string>>(() => ({
  paddingTop: `${(props.yAspect / props.xAspect) * 100}%`,
}))
</script>

<template>
  <div class="tw-relative tw-w-full" :style="ratioPadding">
    <div class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full">
      <slot />
    </div>
  </div>
</template>
